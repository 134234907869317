import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import { usePageContext } from '../hooks/usePageContext'
import { ButtonLink } from '../components/Buttons'

const NotFoundPage = () => {
  const { allMenuEntries } = usePageContext()
  const { formatMessage: t } = useIntl()
  return (
    <>
      <h1 className="c-large">{t({ id: 'title' })}</h1>
      <div className="block c-large">
        <p>{t({ id: 'description' })}</p>
        <ButtonLink to={allMenuEntries[0].link.slug}>
          {t({ id: 'back' })}
        </ButtonLink>
      </div>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query($locale: DATO_SiteLocale) {
    dato {
      ...CommonData
    }
  }
`
