import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import plusSVG from '../icons/plus.svg'
import media from '../styles/mq'

const buttonCss = css`
  display: flex;
  font-weight: 500;
  ${media.greaterThan('small')} {
    display: inline-flex;
  }
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-smallest);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-uppercase);
  padding: 1rem var(--space-medium);
  border: 1px solid var(--color-grey);
  margin-top: var(--space-medium);
  :not(:last-child) {
    margin-bottom: var(--space-medium);
  }
  cursor: pointer;
  transition: background-color 350ms ease;
  :hover {
    background-color: var(--color-lighter);
  }
  img {
    width: 11px;
    margin-right: 0.62rem;
  }
`

export const ButtonLink = ({ children, className, ...props }) => (
  <Link css={[buttonCss, className]} {...props}>
    <img src={plusSVG} alt="icone plus" />
    <span>{children}</span>
  </Link>
)

export const Button = ({ children, className, ...props }) => (
  <div css={[buttonCss, className]} {...props}>
    <img src={plusSVG} alt="icone plus" />
    <span>{children}</span>
  </div>
)
